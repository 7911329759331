<template>
  <b-card
    class="ecommerce-card"
    no-body
  >
    <div class="item-img text-center pt-0">
      <b-link>
        <div
          v-if="image.imgDelete !== '' && Number(image.imgDelete) === 1"
          class="badgeImage"
        >
          <b-button
            variant="danger"
            class="btn-sm"
          >
            <span class="align-middle">{{ $t('Imagem Eliminada') }}</span>
          </b-button>
        </div>
        <b-img
          v-b-modal.modal-xs
          fluid
          class="card-img-top"
          :src="image.src_image_preview + '?v=' + new Date().getTime()"
        />
      </b-link>
    </div>

    <slot name="blockActions" />

  </b-card>
</template>

<script>
import {
  VBTooltip, BCard, BLink, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    image: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>

.badgeImage {
  position: absolute;
  margin-top: 5px;
  margin-left: 5px;
}

</style>
